import {doorServices} from "../config/checkout";

const offerHelper = {
    methods: {
        doorServices(offer){
            const service = doorServices[offer?.door_service];
            return service.label ?? offer?.door_service;
        },

        transitTime(offer) {
            let unit = 'hours';

            if (offer.approx_x_days) {
                return `approx. ${offer.approx_x_days} days`;
            } else {
                if (offer.transit_time.unit === 'M') {
                    unit = 'minutes';
                } else if (offer.transit_time.unit === 'D') {
                    unit = 'days';
                } else if (offer.transit_time.unit === 'W') {
                    unit = 'weeks';
                }

                return `${offer.transit_time.value.total} ${unit}`;
            }
        },
    }
}

export default offerHelper;