<template>
    <Modal id="currency-modal"
           size="lg"
           :centered="true"
           :show="show"
           :staticBackdrop="true"
           v-on="$listeners"
    >
        <div class="pt-6 px-4 px-md-6 text-center">

            <h2 class="font-weight-semibold position-relative mb-5">The credit limit has been exceeded.</h2>

            <p class="mb-4">
                Every account is carefully evaluated to establish a credit limit that we believe is consistent not only
                with our interests, but the interest of the customer as well. We have established {{ user.balance.converted.formatted }}, as your
                credit allowance and believe it is appropriate.
            </p>

            <p class="text-center h5 font-weight-medium mb-4">
                <strong class="font-weight-medium">Since you are at or near that credit limit, we cannot process any
                    more inquiries, until your balance is reduced.</strong>
            </p>
            <p class="mb-4">We would be pleased to review your account with you if you believe an increased credit line is justified. Please chat us and/or order a phone call.</p>
            <p class="mb-0">We are confident you understand the need for this action.</p>
            <div class="mt-5 mb-4">
                <img src="@/assets/img/logo_gepa.svg" alt="Gepa Logistics">
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import {mapGetters} from "vuex";
export default {
    name: "NotEnoughBalanceNoticeModal",
    components: {Modal},
    props: {
        show: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    }
}
</script>

<style scoped>

</style>