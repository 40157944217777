<template>
    <div class="card mt-5">
        <div class="row">
            <div class="col-xl-6">
                <div class="card-body px-lg-4 py-4">
                    <h5 class="text-primary mb-4 font-weight-medium">1. Shipping Route</h5>
                    <p v-if="from" class="mt-5"><strong class="d-block d-sm-inline-block font-weight-medium" style="width: 75px">From</strong>
                        {{ from.address.label }}</p>
                    <p v-if="to"><strong class="d-block d-sm-inline-block font-weight-medium" style="width: 75px">To</strong>
                        {{ to.address.label }}</p>
                    <p v-if="collectionDate" class="mt-5"><strong class="d-inline-block font-weight-medium mr-5">Collection
                        date</strong> {{ collectionDate }}</p>

                </div>
            </div>
            <div class="col-xl-6">
                <MapHere v-if="from && to" :routes="polyline" :from="from" :to="to"/>
            </div>
        </div>

    </div>
</template>

<script>
import MapHere from "@/components/map/MapHere";

export default {
    name: "SummaryShippingRoute",
    props: {
        from: Object,
        to: Object,
        collectionDate: String,
        polyline: Array
    },
    components: {MapHere},
}
</script>