<template>
    <div
        class="col-xl3-6 mb-3 mb-md-5"
    >
        <div class="route-offer-wrap h-100"
             :class="{
            'active': selected,
            'disabled': disabled,
            'placeholder': placeholder
        }">

            <div v-if="placeholder" class="route-offer-wrap-preloader"></div>

            <div class="route-offer"
                 :class="{
                'active': selected,
                'disabled': disabled,
                'placeholder': placeholder
            }"
                 @click="$emit('click', offer)"
            >
                <div class="route-offer-type"
                     :style="{
                   'background-image': `url(${require(`@/assets/img/transports/${offer.type}.svg`)})`
               }"
                >
                </div>

                <div class="d-flex w-100 mb-auto flex-wrap flex-sm-nowrap">
                    <div class="route-offer-image">
                        <img :src="require(`@/assets/img/routes/${offerTypes.GEPAX}.svg`)" alt="">
                    </div>
                    <h5 class="route-offer-title">
                        {{ offer.offer }}
                        <span v-if="offer.name"
                              class="d-block text-secondary small"
                        >
                        {{ offer.name }}

                        <span v-if="offer.base_type === transportBasicTypes.FCL" class="d-block">
                            Container size:&nbsp;{{ offer.container_size }}
                        </span>
                    </span>
                    </h5>
                </div>

                <div class="route-offer-details row mt-4">

                    <div class="col mr-auto route-offer-details-item route-offer-details-item-service">
                        Door service:
                        <strong class="font-weight-medium text-nowrap">{{ doorServices(offer) }}</strong>

                        <div v-if="offer.package.vehicle_type"
                             class="mt-4 route-offer-details-item-package">
                            Vehicle type:
                            <small class="font-weight-medium d-block text-dark mt-1">
                                {{ offer.package.vehicle_type }}
                            </small>
                        </div>
                    </div>

                    <div class="col-5 route-offer-details-item route-offer-details-item-time">
                        Transit time:
                        <strong class="font-weight-medium">{{ transitTime(offer) }}</strong>

                        <div v-if="offer.package.additional_services && offer.package.additional_services.length"
                             class="mt-4 route-offer-details-item-package">
                            Additional services:
                            <ul class="pl-4 mt-1 mb-0">
                                <li v-for="(service) of offer.package.additional_services"
                                    :key="service">
                                    <small class="font-weight-medium d-block text-dark text-nowrap">{{
                                            service
                                        }}</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col ml-auto route-offer-details-item route-offer-details-item-price">
                        Price:
                        <strong class="font-weight-medium text-nowrap">
                            {{ offer.total_price.converted.formatted }}
                        </strong>
                    </div>
                </div>

                <div class="route-offer-package row"
                     v-if="offer.package.vehicle_type || offer.package.additional_services"
                >
                    <div class="col-sm mt-4 ml-auto route-offer-package-service route-offer-details-item"
                         v-if="offer.package.vehicle_type"
                    >
                        Vehicle type:
                        <small class="font-weight-medium d-block text-dark mt-1">{{
                                offer.package.vehicle_type
                            }}</small>
                    </div>
                    <div class="col-sm mt-4 ml-auto route-offer-package-time route-offer-details-item"
                         v-if="offer.package.additional_services.length">
                        Additional services:
                        <ul class="pl-3 mt-1 mb-0">
                            <li v-for="(service) of offer.package.additional_services"
                                :key="service">
                                <small class="font-weight-medium d-block text-dark">{{ service }}</small>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul v-if="offer.disclaimer || offer.package.options"
                    class="mb-0 mt-4 list-unstyled route-offer-option">

                    <template v-if="offer.package.options && offer.package.options.length">
                        <li v-for="(option) of offer.package.options" :key="option"
                            class="mb-1 route-offer-option-item">
                            <small
                                class="d-flex align-items-center"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                     fill="currentColor" class="bi bi-check mr-1" viewBox="0 0 16 16">
                                    <path
                                        d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>

                                {{ option }}
                            </small>
                        </li>
                    </template>

                    <template v-if="offer.disclaimer && offer.disclaimer.length">
                        <li v-for="(disclaimer) of offer.disclaimer"
                            :key="disclaimer"
                            class="mb-1 route-offer-option-item">
                            <small
                                class="d-flex"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16"
                                     fill="currentColor" class="bi bi-info-circle mr-1" viewBox="0 0 16 16"
                                     style="margin-top: 2px">
                                    <path
                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path
                                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                                {{ disclaimer }}
                            </small>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import offerHelper from "../../mixins/offerHelper";
import {offerTypes, transportBasicTypes} from "../../config/checkout";

export default {
    name: "OfferItem",
    data: () => ({
        offerTypes,
        transportBasicTypes
    }),
    props: {
        offer: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: Boolean,
            default: false
        },
    },
    mixins: [offerHelper],
    methods: {}
}
</script>