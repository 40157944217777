<template>
    <PrivateLayout v-if="loaded">
        <div class="card border-none mt-4 overflow-hidden">
            <div class="card-body py-lg-4 px-lg-5">
                <h4 class="text-primary font-weight-semibold mt-3"><span class="font-weight-light">Quote</span>
                    {{ quote.short_reference_key }}</h4>

                <SummaryShippingRoute :from="quote.from"
                                      :to="quote.to"
                                      :collectionDate="quote.collection_date"
                                      :polyline="quote.polyline"
                />

                <SummaryRoutes :routes="quote.routes"
                               :transportTypes="quote.transport_types"
                               :fcl="quote.fcl"
                               @selectedOffer="setOffer"
                               :v="$v.form"
                               :vServer="$vServer"
                />

                <SummaryItems :items="quote.items"
                              :items_unit="quote.items_unit"
                />

                <ShippingInformation
                    :number="4"
                    @change="handleChange"
                    :form="form"
                    :quote="quote"
                    :v="$v.form"
                    :vServer="$vServer"
                />

                <SummaryServiceDetails :serviceDetails="quote.service_details"/>
            </div>

            <SummaryFooter
                :quote="quote"
                :offer="offer"
                @submitQuote="handleSubmit"
            />

        </div>

        <CertifiedLogos class="mt-6 pt-5"/>

        <div class="summary-footer-spacer"></div>

    </PrivateLayout>
</template>

<script>
import {name} from "@/config/app";
import {mapGetters} from 'vuex';
import SummaryFooter from "@/shared/quote-summary/SummaryFooter";
import SummaryItems from "@/shared/quote-summary/SummaryItems";
import ShippingInformation from "@/shared/new-quote/ShippingInformation";
import SummaryRoutes from "@/shared/quote-summary/SummaryRoutes";
import SummaryShippingRoute from "@/shared/quote-summary/SummaryShippingRoute";
import SummaryServiceDetails from "@/shared/quote-summary/SummaryServiceDetails";
import CertifiedLogos from "@/components/CertifiedLogos";
import {maxLength, required} from 'vuelidate/lib/validators'
import {hour} from "@/validators";
import objectPath from 'object-path'
import {scrollToError} from "@/helpers";

export default {
    name: "QuoteSummary",
    components: {
        SummaryRoutes,
        SummaryItems,
        ShippingInformation,
        SummaryFooter,
        SummaryShippingRoute,
        CertifiedLogos,
        SummaryServiceDetails
    },
    middleware: ['auth', 'check-quote'],
    meta: {
        title: `Quote Summary - ${name}`
    },
    data: () => ({
        loaded: false,
        submitted: false,
        route: null,
        offer: null,

        form: {
            reference_key: null,
            offer_number: null,
            shipping: {
                collection_reference: null,
                delivery_reference: null,
                other_requirements: null,
                shipper_details: null,
                shipper_phone: null,
                consignee_details: null,
                consignee_phone: null,
            },
            additional_information: {
                preferred_time_start: null,
                preferred_time_end: null,
            },
            loading_from: {
                company_name: null,
                house_number: null,
                street: null,
                postal_code: null,
            },
            delivery_to: {
                company_name: null,
                house_number: null,
                street: null,
                postal_code: null,
            },
        }

    }),
    validations: {
        form: {
            offer_number: {required},
            shipping: {
                collection_reference: {
                    required,
                    maxLength: maxLength(128)
                },
                delivery_reference: {
                    required,
                    maxLength: maxLength(128),
                },
                other_requirements: {
                    maxLength: maxLength(500),
                },
                shipper_details: {
                    required,
                    maxLength: maxLength(80)
                },
                shipper_phone: {required},
                consignee_details: {
                    required,
                    maxLength: maxLength(80)
                },
                consignee_phone: {required},
            },
            loading_from: {
                company_name: {required},
                house_number: {required},
                street: {required},
                postal_code: {required},
            },
            delivery_to: {
                company_name: {required},
                house_number: {required},
                street: {required},
                postal_code: {required},
            },
            additional_information: {
                preferred_time_start: {hour},
                preferred_time_end: {hour},
            }
        }
    },
    async beforeCreate() {
        await Promise.all([
            this.$store.dispatch('units/fetchUnits'),
            this.$store.dispatch('units/fetchVehicles'),
        ]);

        this.assignLoadingFrom();
        this.assignDeliveryTo();
        this.loaded = true;
    },
    computed: {
        ...mapGetters({
            '$vServer': 'serverNotice/errors',
            quote: 'checkout/quote',
            newQuote: 'checkout/newQuote'
        }),
    },
    methods: {
        setOffer({offer}) {
            this.offer = offer;

            this.form.reference_key = this.quote.reference_key
            this.form.offer_number = offer ? offer.offer_number : null;
        },

        handleChange({name, value}) {
            if (!name) return;
            objectPath.set(this.form, name, value)
        },

        async handleSubmit(check) {
            if (this.submitted || !check) return;
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                this.submitted = true;
                this.$store.dispatch('serverNotice/clearNotice');
                try {
                    this.$root.$preloader.start();
                    await this.$store.dispatch('checkout/dispatchNewBooking', this.form)
                    await this.$store.dispatch('auth/fetchUser', this.form)
                    this.$store.dispatch('user/clearStoredBalanceHistory')
                    this.$store.dispatch('user/clearStoredBookings')
                    this.$router.push({name: 'dashboard'});
                } catch (e) {
                    const {data, status} = e.response;
                    if (!data.status)
                        data.status = status;
                    this.$store.dispatch('serverNotice/setNotice', data);
                    this.$root.$preloader.finish();
                }

                this.submitted = false;
            }
        },

        assignLoadingFrom() {
            const {from: {address}} = this.quote;
            this.form.loading_from = {
                company_name: address.company_name || null,
                house_number: address.houseNumber || null,
                street: address.street || null,
                postal_code: address.postalCode || null,
            };
        },

        assignDeliveryTo() {
            const {to: {address}} = this.quote;
            this.form.delivery_to = {
                company_name: address.company_name || null,
                house_number: address.company_name || null,
                street: address.street || null,
                postal_code: address.postalCode || null,
            };
        }
    }
}
</script>


<style scoped lang="scss">
.summary-footer-spacer {
    @include media-breakpoint-down('md') {
        margin-bottom: 75px;
    }
}
</style>