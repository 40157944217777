<template>
    <div id="map"></div>
</template>

<script>
import H from '@here/maps-api-for-javascript';
import config from "@/config/maps";
import arc from 'arc';

export default {
    name: "MapHere",
    data: () => ({
        // colors: ['blue', 'orange', 'brown', 'yellow', 'green', 'black', 'gray', 'pink', 'red', 'purple'],
        platform: null,
        map: null,
        behavior: null,
        fromMarker: null,
        toMarker: null,
        router: null,
    }),
    props: {
        routes: [Object, Array],
        from: {
            type: Object,
            required: true,
        },
        to: {
            type: Object,
            required: true,
        }
    },
    watch: {
        from() {
            if (this.checkProps)
                this.renderMap();
        },
        to() {
            if (this.checkProps)
                this.renderMap();
        },
    },

    mounted() {
        const mapConfig = config.services[config.use];
        this.platform = new H.service.Platform({
            'apikey': mapConfig.js_api_key,
        });
        const maptypes = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.$el,
            maptypes.vector.normal.map,
            {
                center: mapConfig.map.defaultCenter,
                zoom: mapConfig.map.zoom,
                padding: {top: 50, left: 50, bottom: 50, right: 50},
                // pixelRatio: window.devicePixelRatio || 1
            });
        this.ui = H.ui.UI.createDefault(this.map, maptypes);
        this.behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

        window.addEventListener('resize', () => {
            this.map.getViewPort().resize();
        });

        if (this.checkProps)
            this.renderMap();
    },
    methods: {
        async renderMap() {
            const [fromLat, fromLng] = this.from.position.split(',');
            const fromPosition = {lng: parseFloat(fromLng), lat: parseFloat(fromLat)};

            const [toLat, toLng] = this.to.position.split(',');
            const toPosition = {lng: parseFloat(toLng), lat: parseFloat(toLat)};

            this.fromMarker = new H.map.Marker(fromPosition);
            const start = {x: fromPosition.lng, y: fromPosition.lat}

            this.toMarker = new H.map.Marker(toPosition);
            const end = {x: toPosition.lng, y: toPosition.lat}

            const generatorArc = new arc.GreatCircle(start, end);
            const lineString = generatorArc.Arc(50,{offset:10});
            const polyline = new H.map.Polyline(H.util.wkt.toGeometry(lineString.wkt()));
            polyline.setStyle({
                lineWidth: 4,
                strokeColor: 'rgba(250, 0, 0, 1)',
            })

            this.map.removeObjects(this.map.getObjects());
            this.map.addObjects([this.fromMarker, this.toMarker, polyline]);
            this.map.setCenter(this.mapCenter(fromPosition, toPosition));

            // this.map.addObjects([this.fromMarker, this.toMarker]);
            // this.renderRoutes(this.routes);
        },
        mapCenter(from, to) {
            return {
                lng: (from.lng + to.lng) / 2,
                lat: (from.lat + to.lat) / 2
            }
        },
        // renderRoutes(routes) {
        //     const renderPolyline = polyline => {
        //         const linestring = H.geo.LineString.fromFlexiblePolyline(polyline);
        //         const routeLine = new H.map.Polyline(linestring, {
        //             style: {strokeColor: this.colors[i], lineWidth: 5}
        //         });
        //         this.map.addObjects([routeLine]);
        //         this.map.getViewModel().setLookAtData({bounds: routeLine.getBoundingBox()});
        //     }
        //
        //     let i = 0;
        //     for (const [, polylines] of routes.entries()) {
        //         if (Array.isArray(polylines)) {
        //             polylines.forEach((polyline) => {
        //                 renderPolyline(polyline)
        //                 i++;
        //             })
        //         } else if(typeof polylines === 'string'){
        //             renderPolyline(polylines)
        //         }
        //         i++;
        //     }
        // },
    },
    computed: {
        checkProps() {
            const {from, to} = this;
            if (typeof from == 'object' && typeof to == 'object') {
                return true;
            }
            return false;
        }


    }
}
</script>

<style scoped lang="scss">
#map {
    height: 250px;

    .H {
        &_imprint {
            display: none !important;
        }
    }
}

</style>