<template>
    <div class="card mt-5 mb-4"
         v-if="checkShow()"
    >
        <div class="card-body p-4">
            <h5 class="text-primary mb-4 font-weight-medium">5. Service details</h5>
            <p v-if="serviceDetails.class_hazardous_goods" class="d-flex flex-column card-text">
                <strong class="font-weight-medium mb-2">Class hazardous goods</strong>
                {{ castClassHazardousGoods }}
            </p>
            <p v-if="serviceDetails.temp_conditions" class="d-flex flex-column card-text">
                <strong class="font-weight-medium mb-2">Required temp. conditions</strong>
                {{ castTempCondition }}
            </p>
            <p v-if="serviceDetails.insurance" class="d-flex flex-wrap card-text">
                <strong class="font-weight-medium mb-2 mr-2">Extra cargo insurance:</strong>
                {{ serviceDetails.insurance_value_advanced.converted.formatted }}
            </p>
        </div>
    </div>
</template>

<script>
import {additional_services, class_hazardous_goods, temp_conditions} from "@/config/checkout";

export default {
    name: "SummaryServiceDetails",
    data: () => ({
        additionalServices: additional_services,
        tempConditions: temp_conditions,
        classHazardousGoods: class_hazardous_goods,
    }),
    props: {
        serviceDetails: Object
    },
    computed: {
        castAdditionalServices() {
            return this.additionalServices.filter(service => {
                return this.serviceDetails.additional_services.includes(service.name);
            });
        },

        castTempCondition() {
            return this.tempConditions[this.serviceDetails.temp_conditions];
        },

        castClassHazardousGoods() {
            return this.classHazardousGoods[this.serviceDetails.class_hazardous_goods];
        },
    },
    methods: {
        checkShow() {
            const {serviceDetails} = this;
            return serviceDetails.class_hazardous_goods || serviceDetails.temp_conditions || serviceDetails.insurance;
        }
    }
}
</script>