<template>
    <div v-if="data">
        <table class="table table-borderless">
            <TableHead
                :cols="cols"
                v-on="$listeners"/>

            <tbody class="table-body">

            <template v-for="(row, i) in data">
                <TableBodyRow :cols="cols" :row="row" :key="i" :index="i">
                    <template v-for="(col,colId) in cols" :slot="colId">
                        <slot :name="colId" :row="row"></slot>
                    </template>
                </TableBodyRow>
            </template>

            </tbody>
        </table>

    </div>
</template>

<script>
import TableHead from "@/components/table/TableHead";
import TableBodyRow from "@/components/table/TableBodyRow";

export default {
    name: "Table",
    components: {TableBodyRow, TableHead},
    props: {
        cols: {
            required: true,
            type: Object,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        data: {
            type: [Array, Boolean, null, undefined],
            default: null,
        },
    },
}
</script>
<style lang="scss">
.table {
    &-header {
        &-column {
            font-weight: $font-weight-base;
            padding-top: 0.65rem !important;
            padding-bottom: 0.65rem !important;
            white-space: nowrap;

            &-sortable {
                cursor: pointer;
            }

            &-active {
                font-weight: $font-weight-semibold;
            }

            &:first-child {
                border-top-left-radius: $border-radius-lg;
                border-bottom-left-radius: $border-radius-lg;
                text-align: center;
            }

            &:last-child {
                border-top-right-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
            }

            &-arrow {
                background-image: escape-svg($custom-select-indicator);
                width: 10px;
                height: 10px;
                background-size: cover;
                display: inline-flex;
                filter: invert(1);
                vertical-align: -1px;
                margin-left: 5px;

                &-up {
                    transform: rotate(180deg);
                }
            }

            a {
                color: inherit;
                display: block;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    &-body {
        &-column {
            font-size: $font-size-sm;
            padding-top: 1.25rem !important;
            padding-bottom: 1.25rem !important;
        }
    }

    &-row {
        &-even {
            @include media-breakpoint-down('md') {
                background-color: rgba($primary, 0.1);
            }

            .table-body-column {
                @include media-breakpoint-up('lg') {
                    background-color: rgba($primary, 0.1);
                }

                &:first-child {
                    border-top-left-radius: $border-radius-lg;
                    border-bottom-left-radius: $border-radius-lg;
                }

                &:last-child {
                    border-top-right-radius: $border-radius-lg;
                    border-bottom-right-radius: $border-radius-lg;
                }
            }
        }
    }

}
</style>