<template>
    <div class="col-xl3-6 mb-3 mb-md-5">
        <div class="route-offer-wrap h-100">
            <div class="route-offer route-offer-skeleton">
                <div class="route-offer-type route-offer-animation"></div>
                <div class="d-flex w-100 mb-auto flex-wrap flex-sm-nowrap">
                    <div class="route-offer-image route-offer-animation"></div>
                    <h5 class="route-offer-title route-offer-animation"></h5>
                </div>

                <div class="route-offer-details row mt-4">
                    <div
                        class="col mr-auto route-offer-details-item route-offer-animation route-offer-details-item-service">
                        <div></div>
                    </div>
                    <div class="col-5 route-offer-details-item route-offer-animation route-offer-details-item-time">
                        <div></div>
                    </div>
                    <div
                        class="col ml-auto route-offer-details-item route-offer-animation route-offer-details-item-price">
                        <div></div>
                    </div>
                </div>

                <div class="mb-0 mt-4 route-offer-option route-offer-animation"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OfferLoader"
}
</script>

<style scoped lang="scss">
@keyframes skeletonAnimation {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: .4;
    }
    100% {
        opacity: 0.8;
    }
}

.route-offer-skeleton {
    border-color: $gray-100;
    cursor: default;

    .route-offer {
        &-animation {
            animation-name: skeletonAnimation;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        &-type {
            background-color: $gray-200;
        }

        &-image {
            background-color: $gray-200;
            height: 38px;
            border-radius: 3px;

            @include media-breakpoint-down-px(479) {
                width: 150px;
            }
        }

        &-title {
            background-color: $gray-200;
            height: 38px;
            border-radius: 3px;
        }

        &-details {
            &-item {
                > * {
                    background-color: $gray-200;
                    height: 52px;
                    border-radius: 3px;
                    width: 100%;
                }
            }
        }

        &-option {
            background-color: $gray-200;
            height: 45px;
            border-radius: 3px;
        }
    }
}

</style>