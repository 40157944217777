var render = function render(){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"table-header"},[_c('tr',[_vm._l((_vm.cols),function(colData,col){return [(colData.show === undefined || colData.show === true)?_c('th',{key:col,staticClass:"table-header-column bg-primary text-white",class:{
                    [`table-body-column-${col}`]: `table-body-column-${col}`,
                    'table-header-column-sortable': colData.sortable,
                    'table-header-column-active': colData.active
                },attrs:{"scope":"col"},on:{"click":function($event){return _vm.handleClickSortable(col)}}},[_vm._t(`header${col}`,function(){return [_vm._v(" "+_vm._s(colData.label || col)+" "),(colData.sortable)?_c('span',{staticClass:"table-header-column-arrow",class:{
                        'table-header-column-arrow-up': _vm.order === 'asc',
                        'table-header-column-arrow-down': _vm.order === 'desc'
                    }}):_vm._e()]})],2):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }