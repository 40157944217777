<template>
    <thead class="table-header">
    <tr>
        <template v-for="(colData, col) in cols">
            <th
                v-if="colData.show === undefined || colData.show === true"
                :key="col"
                scope="col"
                class="table-header-column bg-primary text-white"
                :class="{
                        [`table-body-column-${col}`]: `table-body-column-${col}`,
                        'table-header-column-sortable': colData.sortable,
                        'table-header-column-active': colData.active
                    }"
                @click="handleClickSortable(col)"
            >
                <slot :name="`header${col}`">
                    {{ colData.label || col }}
                    <span
                        v-if="colData.sortable"
                        class='table-header-column-arrow'
                        :class="{
                            'table-header-column-arrow-up': order === 'asc',
                            'table-header-column-arrow-down': order === 'desc'
                        }"
                    >
                    </span>
                </slot>
            </th>
        </template>
    </tr>
    </thead>
</template>

<script>

export default {
    name: "TableHead",
    data: () => ({
        orderBy: null,
        order: null,
    }),
    props: {
        cols: {
            required: true,
            type: Object,
        },
    },
    created() {
        for (const [key, col] of Object.entries(this.cols)) {
            if (col.active) {
                this.orderBy = key;
                this.order = col.order;
            }
        }
    },
    methods: {
        handleClickSortable(col) {
            const column = this.cols[col];
            if (!column.sortable) return false;

            if(col === this.orderBy){
                this.order = this.order == 'desc'
                    ? 'asc'
                    : 'desc';
            } else{
                this.orderBy = col;
                this.order = 'desc';
            }
            this.$emit('sort', {
                order: this.order,
                orderBy: this.orderBy,
            })
        }
    }
}
</script>

<style scoped>

</style>