<template>
    <footer class="summary-footer py-md-4 px-md-5 bg-primary d-flex">
        <router-link :to="{name: 'new-quote', params: { type: returnQuoteType() }}" class="summary-footer-btn-back btn p-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-left-square-fill mr-3" viewBox="0 0 16 16">
                <path
                    d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
            </svg>
            <span class="d-none d-xl2-inline-block">
                back to quote form
            </span>

        </router-link>

        <div class="summary-footer-details ml-sm-auto row align-items-center">
            <div class="col-sm d-flex summary-footer-details-wrap">
                <transition name="fade">
                    <span v-if="transitTimeCast" class="h3 font-weight-light mb-sm-0 text-white mr-sm-4 mr-md-6 mr-xl3-9">
                        Transit time:
                        <strong class="font-weight-bold">{{ transitTimeCast }}</strong>
                    </span>
                </transition>
                <transition name="fade">
                    <span v-if="offer" class="h3 font-weight-light mb-sm-0 text-white mr-md-4 mr-xl3-7">
                        Price:
                        <strong class="font-weight-bold">{{ offer.total_price.converted.formatted }}</strong>
                    </span>
                </transition>
            </div>
            <div class="col-sm-auto">
                <button class="summary-footer-btn-submit btn btn-lg"
                        @click.prevent="submitQuote"
                        :disabled="!offer"
                >
                    Book
                </button>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "SummaryFooter",
    props: {
        quote: {
            type: Object,
        },
        offer: {
            type: [Object, null],
            default: null,
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleScroll);
    },
    async mounted() {
        setTimeout(() => {
            this.handleScroll();
        }, 50);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);

    },
    computed: {
        transitTimeCast() {
            if (!this.offer) return null;

            if(this.offer.approx_x_days){
                return `approx. ${this.offer.approx_x_days} days`;
            }
            else{
                let unit = 'hours';
                if (this.offer.transit_time.unit === 'M') {
                    unit = 'minutes';
                } else if (this.offer.transit_time.unit === 'D') {
                    unit = 'days';
                } else if (this.offer.transit_time.unit === 'W') {
                    unit = 'weeks';
                }

                return `${this.offer.transit_time.value.total} ${unit}`;
            }
        },
    },
    methods: {
        returnQuoteType(){
            if(this.quote.transport_types.length === 1){
                const [type] = this.quote.transport_types;
                return type;
            }
            return null;
        },

        handleScroll() {
            if(window.innerWidth >= 992){
                const scroll = window.pageYOffset + window.innerHeight;
                const parentOffsetTop = this.$el.parentElement.offsetTop;

                let offset = this.$el.offsetTop + this.$el.offsetHeight - (scroll - parentOffsetTop)
                if (offset < 0) offset = 0;

                this.$el.style.transform = `translateY(-${offset}px)`;
            }
        },

        submitQuote(){
            this.$emit('submitQuote', this.offer)
        }
    }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.15s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: scale(1);
}

.summary-footer {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down('md'){
        position: fixed;
        transform: none !important;
        bottom: 75px;
        width: 100%;
        left: 0;
        padding: 10px 15px;
    }

    @include media-breakpoint-down('sm'){
        bottom: 61px;
    }

    &-details{
        @include media-breakpoint-down('xs'){
           flex: 1;
        }

        &-wrap{
            @include media-breakpoint-down('xs'){
                margin-left: -65px;
                width: calc(100% + 65px)
            }
        }

        span{
            white-space: nowrap;
            @include media-breakpoint-down('xl2'){
                font-size: $h3-font-size * .9;
            }

            @include media-breakpoint-down('xl'){
                display: inline-flex;
                flex-direction: column;
                line-height: 1em;
                font-size: $h3-font-size * .7;
            }

            @include media-breakpoint-down('md'){

            }
            @include media-breakpoint-down('xs'){

                min-width: 50%;
                flex: 0 0 auto;
                margin-bottom: 20px;
                padding-right: 15px;
                margin-top: 3px;
            }

            strong{
                @include media-breakpoint-down('xl') {
                    font-size: $h3-font-size * .9;
                }
            }
        }
    }

    &-btn {
        &-back {
            color: $white;
            opacity: 0.7;
            text-decoration: none;
            display: flex;
            align-items: center;
            @include transition($btn-transition);

            &:hover, &:focus {
                opacity: 1;
            }

            @include media-breakpoint-down('xs'){
            height: 100%;
            margin-top: auto;
        }
            svg {
                width: 30px;
                height: 30px;

                @include media-breakpoint-down('xs'){
                    width: 50px;
                    height: 50px;
                }
            }
        }

        &-submit {
            background-color: $white;
            min-width: 120px;
            min-height: 50px;
            font-size: $nav-link-font-size;
            color: $primary;
            font-weight: $font-weight-semibold;
            opacity: 0.5;
            border-width: 3px;
            transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out,
            opacity 0.15s ease-in-out;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.2;
            }

            &:hover:not(:disabled), &:focus:not(:disabled) {
                opacity: 1;
                border-color: $white;
                color: $white;
                background-color: $primary;
            }

            @include media-breakpoint-down('xs'){
                width: 100%;
            }
        }
    }
}
</style>