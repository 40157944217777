<template>
    <tr
        :key="row[primaryKey]"
        class="table-row"
        :class="{'table-row-even': index%2, 'table-row-odd': !(index%2)}"
    >
        <template v-for="(colData, col) in cols">
            <td v-if="colData.show === undefined || colData.show === true"
                :key="col"
                class="table-body-column"
                :class="`table-body-column-${col}`"
            >
                <div :title="colContent(row[col], colData)">
                    <slot :name="col"
                          :row="row"
                    >
                        <label v-if="isMobile" class="mb-0">{{ colData.label || col }}</label>
                        {{ colContent(row[col], colData) }}
                    </slot>
                </div>
            </td>
        </template>

    </tr>
</template>

<script>
export default {
    name: "TableBodyRow",
    data: () => ({
        windowWidth: window.innerWidth,
    }),
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    props: {
        index: Number,
        cols: {
            required: true,
            type: Object,
        },
        row: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 991;
        },
        primaryKey() {
            let primaryKey = 'id';
            const {cols} = this;
            if (cols) {
                Object.keys(cols).forEach((key, i) => {
                    if (i === 0) primaryKey = key;

                    if (cols[key].primary) {
                        primaryKey = key;
                    }
                })
            }

            return primaryKey;
        },
    },
    methods: {
        colContent(content, data) {
            if (content) {
                if (data.prefix) content = `${data.prefix} ${content}`;
                if (data.suffix) content += ` ${data.suffix}`;
                return content;
            } else return '-';
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    }
}
</script>

<style scoped>

</style>