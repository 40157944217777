<template>
    <div class="card mt-5 mb-4">
        <div class="card-body px-lg-4 py-4">
            <h5 class="text-primary mb-4 font-weight-medium">{{ number }}. Shipping Information</h5>
            <h6 class="font-weight-medium mb-4">Shipper</h6>
            <div class="row">
                <div class="col-xl-6">
                    <Input
                        label="Company Name"
                        name="loading_from.company_name"
                        placeholder="Enter company name"
                        :value="form.loading_from.company_name"
                        :v="vServer['loading_from.company_name'] || v.loading_from.company_name"
                        v-on="$listeners"
                        autocomplete="organization"
                    />
                </div>

                <span class="w-100"></span>

                <div class="col-md-6 mt-4">
                    <Input
                        label="Contact Details"
                        name="shipping.shipper_details"
                        placeholder="Enter Shipper Name"
                        :value="form.shipping.shipper_details"
                        :v="vServer['shipping.shipper_details'] || v.shipping.shipper_details"
                        v-on="$listeners"
                    />
                </div>
                <div class="col-md-6 col-xl3-5 mt-4">
                    <InputPhone
                        label="Phone"
                        name="shipping.shipper_phone"
                        :value="form.shipping.shipper_phone"
                        :v="vServer['shipping.shipper_phone'] || v.shipping.shipper_phone"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-8 col-md-6 mt-4">
                    <Input
                        label="Street"
                        name="loading_from.street"
                        placeholder="Enter street"
                        :value="form.loading_from.street"
                        :v="vServer['loading_from.street'] || v.loading_from.street"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-4 col-md-3 col-xl3-2 mt-4">
                    <Input
                        label="House Number"
                        name="loading_from.house_number"
                        placeholder="Enter house number"
                        :value="form.loading_from.house_number"
                        :v="vServer['loading_from.house_number'] || v.loading_from.house_number"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-6 col-md-3 mt-4">
                    <Input
                        label="Postal Code"
                        name="loading_from.postal_code"
                        placeholder="Enter postal code"
                        :value="form.loading_from.postal_code"
                        :v="vServer['loading_from.postal_code'] || v.loading_from.postal_code"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-6 col-md-4 mt-4">
                    <Input
                        label="City"
                        name="shipper_city"
                        :value="quote.from.address.city"
                        :readonly="true"
                    />
                </div>

                <div class="col-sm-6 col-md-4 mt-4">
                    <Input
                        label="State"
                        name="shipper_state"
                        :value="quote.from.address.state"
                        :readonly="true"
                    />
                </div>

                <div class="col-sm-6 col-md-4 mt-4">
                    <Input
                        label="Country"
                        name="shipper_country"
                        :value="quote.from.address.countryName"
                        :readonly="true"
                    />
                </div>
            </div>

            <hr class="my-6">

            <h6 class="font-weight-medium mb-4">Consignee</h6>

            <div class="row">

                <div class="col-xl-6">
                    <Input
                        label="Company Name"
                        name="delivery_to.company_name"
                        placeholder="Enter company name"
                        :value="form.delivery_to.company_name"
                        :v="vServer['delivery_to.company_name'] || v.delivery_to.company_name"
                        v-on="$listeners"
                        autocomplete="organization"
                    />
                </div>

                <span class="w-100"></span>

                <div class="col-md-6 mt-4">
                    <Input
                        label="Contact Details"
                        name="shipping.consignee_details"
                        placeholder="Enter Consignee Name"
                        :value="form.shipping.consignee_details"
                        :v="vServer['shipping.consignee_details'] || v.shipping.consignee_details"
                        v-on="$listeners"
                    />
                </div>
                <div class="col-md-6 col-xl3-5 mt-4">
                    <InputPhone
                        label="Phone"
                        name="shipping.consignee_phone"
                        :value="form.shipping.consignee_phone"
                        :v="vServer['shipping.consignee_phone'] || v.shipping.consignee_phone"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-8 col-md-6 mt-4">
                    <Input
                        label="Street"
                        name="delivery_to.street"
                        placeholder="Enter street"
                        :value="form.delivery_to.street"
                        :v="vServer['delivery_to.street'] || v.delivery_to.street"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-4 col-md-3 col-xl3-2 mt-4">
                    <Input
                        label="House Number"
                        name="delivery_to.house_number"
                        placeholder="Enter house number"
                        :value="form.delivery_to.house_number"
                        :v="vServer['delivery_to.house_number'] || v.delivery_to.house_number"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-6 col-md-3 mt-4">
                    <Input
                        label="Postal Code"
                        name="delivery_to.postal_code"
                        placeholder="Enter postal code"
                        :value="form.delivery_to.postal_code"
                        :v="vServer['delivery_to.postal_code'] || v.delivery_to.postal_code"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-6 col-md-4 mt-4">
                    <Input
                        label="City"
                        name="consignee_city"
                        :value="quote.to.address.city"
                        :readonly="true"
                    />
                </div>

                <div class="col-sm-6 col-md-4 mt-4">
                    <Input
                        label="State"
                        name="consignee_state"
                        :value="quote.to.address.state"
                        :readonly="true"
                    />
                </div>

                <div class="col-sm-6 col-md-4 mt-4">
                    <Input
                        label="Country"
                        name="consignee_country"
                        :value="quote.to.address.countryName"
                        :readonly="true"
                    />
                </div>

            </div>

            <hr class="my-6">

            <div class="row mt-4">
                <div class="col-sm-6">
                    <Input
                        label="Collection Reference"
                        name="shipping.collection_reference"
                        placeholder="Enter Collection Reference"
                        :value="form.shipping.collection_reference"
                        :v="vServer['shipping.collection_reference'] || v.shipping.collection_reference"
                        v-on="$listeners"
                    />
                </div>

                <div class="col-sm-6 mt-4 mt-sm-0">
                    <Input
                        label="Delivery Reference"
                        name="shipping.delivery_reference"
                        placeholder="Enter Delivery Reference"
                        :value="form.shipping.delivery_reference"
                        :v="vServer['shipping.delivery_reference'] || v.shipping.delivery_reference"
                        v-on="$listeners"
                    />
                </div>

                <div class="col mt-4">
                    <Textarea
                        label="Other Requirements"
                        name="shipping.other_requirements"
                        placeholder="Enter Other Requirements"
                        :value="form.shipping.other_requirements"
                        :v="vServer['shipping.other_requirements'] || v.shipping.other_requirements"
                        v-on="$listeners"
                    />
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-lg-5">
                    <label class="d-block font-weight-medium">
                        Preferred time range for a collection
                        <Tooltip
                            :style="{marginLeft: '5px'}"
                            content="According to the local time zone in the country of collection."
                        />
                    </label>

                    <div class="d-flex align-items-center">
                        <Checkbox
                            class="mb-0 mr-2"
                            name="enable_preferred_time"
                            @change="enablePreferredTime = $event.value"
                        />
                        <InputHour name="additional_information.preferred_time_start"
                                   class="mb-0"
                                   :disabled="!enablePreferredTime"
                                   @change="handlePreferredTimeChange"
                                   :value="this.additional_information.preferred_time_start"
                                   :v="vServer['additional_information.preferred_time_start'] || v.additional_information.preferred_time_start"
                        />
                        <span class="mx-3">-</span>
                        <InputHour name="additional_information.preferred_time_end"
                                   class="mb-0"
                                   :disabled="!enablePreferredTime"
                                   @change="handlePreferredTimeChange"
                                   :value="this.additional_information.preferred_time_end"
                                   :v="vServer['additional_information.preferred_time_end'] || v.additional_information.preferred_time_end"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Textarea from "@/components/form/Textarea";
import Input from "@/components/form/Input";
import InputPhone from "@/components/form/InputPhone";
import objectPath from "object-path";
import InputHour from "@/components/form/InputHour";
import Checkbox from "@/components/form/Checkbox";
import moment from "moment";
import Tooltip from "@/components/notices/InfoTooltip";

export default {
    name: "ShippingInformation",
    components: {Input, InputHour, Checkbox, Tooltip, Textarea, InputPhone},
    data: () => ({
        hourFormat: 'HH:mm',
        enablePreferredTime: false,
        additional_information: {
            preferred_time_start: null,
            preferred_time_end: null,
        }
    }),
    props: {
        form: Object,
        v: Object,
        vServer: Object,
        number: {
            type: Number,
            default: 3
        },
        quote: {
            type: Object,
            required: true,
        }
    },
    methods: {
        handlePreferredTimeChange({name, value}){
            if (!name) return;
            objectPath.set(this, name, value)
        },

        emitChangePreferredTime(){
            const {preferred_time_start, preferred_time_end} = this.additional_information;
            this.$emit('change', {name: 'additional_information.preferred_time_start', value: preferred_time_start});
            this.$emit('change', {name: 'additional_information.preferred_time_end', value: preferred_time_end});
        }
    },
    watch: {
        'additional_information.preferred_time_start': function (preferredTimeStart){
            const preferredTimeEnd = this.additional_information.preferred_time_end;

            if(!preferredTimeEnd){
                this.emitChangePreferredTime();
                return;
            }

            const preferredTimeEndDate = moment(preferredTimeEnd, this.hourFormat),
                preferredTimeStartDate = moment(preferredTimeStart, this.hourFormat);

            if(preferredTimeStartDate.isAfter(preferredTimeEndDate)){
                this.additional_information.preferred_time_end = preferredTimeStartDate.format(this.hourFormat)
            }
            this.emitChangePreferredTime()
        },

        'additional_information.preferred_time_end': function (preferredTimeEnd){
            const preferredTimeStart = this.additional_information.preferred_time_start;

            if(!preferredTimeStart) {
                this.emitChangePreferredTime();
                return;
            }

            const preferredTimeEndDate = moment(preferredTimeEnd, this.hourFormat),
                preferredTimeStartDate = moment(preferredTimeStart, this.hourFormat);

            if(preferredTimeEndDate.isBefore(preferredTimeStartDate)){
                this.additional_information.preferred_time_start = preferredTimeEndDate.format(this.hourFormat)
            }
            this.emitChangePreferredTime();
        }
    }
}
</script>
