<template>
  <div class="font-weight-medium alert alert-holiday text-white alert-danger py-4 bg-danger">
    <p>Due to the limited availability of services and congestion caused by the Christmas period, there may be delays in
      the execution of collection and deliveries regardless of the transport mode. The rates applying to the collections
      planned from 16th December 2022 to 10th January 2023 might be subject to the final verification of possibilities
      to be done by your Key Account Manager.</p>
    <p>Please consider the risk of having no capabilities to arrange the transportation process in the mentioned period
      and/or additional charges applying.</p>
    <p class="mb-0">Before booking in the mentioned period, we recommend contacting your Key Account Manager first over
      the live chat on GEPAX platform or e-mail.</p>
  </div>
</template>

<script>
export default {
  name: "HolidayAlert"
}
</script>

<style scoped lang="scss">
.alert {
  &-holiday {
    margin: -25px -33px 2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include media-breakpoint-down('md') {
      margin: -21px -21px 2rem;
    }

    @include media-breakpoint-down('sm') {
      margin: -21px -16px 2rem;
    }
  }
}
</style>
