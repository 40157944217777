<template>
    <div class="card mt-5 mb-4">
        <div class="card-body py-lg-4 px-lg-5">
            <HolidayAlert />

            <h5 class="text-primary mb-4 font-weight-medium">2. Route offer</h5>

            <p class="mb-3 mb-md-5"><strong class="font-weight-medium">Select the offer:</strong></p>

            <p v-if="vServer.offer_number" class="text-danger is-invalid">{{ vServer.offer_number }}</p>
            <p v-if="v.offer_number.$error && !v.offer_number.required" class="text-danger is-invalid">It is required to
                select an offer.</p>

            <div v-if="fclRoutes.length || otherRoutes.length">


                <div v-if="otherRoutes.length"
                     class="route-wrapper row">
                    <template v-for="(route) of otherRoutes">
                        <OfferItem
                            :offer="route"
                            :key="route.offer_number"
                            :selected="isSelected(route)"
                            :disabled="!hasEnoughBalance(route)"
                            @click="handleSelectOffer"
                        />
                    </template>
                </div>
                <div v-else
                     class="route-wrapper row">
                    <EmptyOffers/>
                </div>

                <hr v-if="hasSeaTransportType" style="background-color: #CCE8F6" class="mt-5 mt-md-0 mb-6 mb-md-5">

                <p v-if="hasSeaTransportType"
                   class="mb-3 mb-md-5">
                    <strong class="font-weight-medium">
                        Full container offers:
                    </strong>
                </p>

                <div class="route-wrapper row">
                    <template v-for="(route) of fclRoutes">
                        <OfferItem
                            :offer="route"
                            :key="route.offer_number"
                            :selected="isSelected(route)"
                            :disabled="!hasEnoughBalance(route)"
                            @click="handleSelectOffer"
                        />
                    </template>

                    <EmptyOffers v-if="hasSeaTransportType && loadedContainerOffers && !fclRoutes.length"/>

                    <template v-for="index in 2">
                        <OfferLoader v-if="hasSeaTransportType && !loadedContainerOffers"
                                     :key="index"
                        />
                    </template>

                </div>

            </div>

            <div class="route-wrapper row"
                 v-else-if="!hasSeaTransportType || loadedContainerOffers">
                <EmptyOffers/>
            </div>

        </div>

        <NotEnoughBalanceNoticeModal :show="showBalanceNoticeModal"
                                     @hideModal="showBalanceNoticeModal = false"
        />
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import NotEnoughBalanceNoticeModal from "@/shared/quote-summary/NotEnoughBalanceNoticeModal";
import {offerTypes, transportTypes, transportBasicTypes} from "../../config/checkout";
import OfferItem from "./OfferItem";
import EmptyOffers from "./EmptyOffers";
import OfferLoader from "./OfferLoader";
import HolidayAlert from "./HolidayAlert";

export default {
    name: "SummaryRoutes",
    data: () => ({
        selectedOffer: null,
        showBalanceNoticeModal: false,
        offerTypes,
        containerOffers: [],
        loadedContainerOffers: false,
    }),
    components: {HolidayAlert, OfferLoader, OfferItem, EmptyOffers, NotEnoughBalanceNoticeModal},
    props: {
        routes: Array,
        v: Object,
        vServer: Object,
        transportTypes: Array,
        fcl: Array,
    },
    created() {
        this.containerOffers = this.fcl;

        if (!this.hasSeaTransportType || !this.canLoadedContainerOffers) {
            this.loadedContainerOffers = true;
        } else if (this.canLoadedContainerOffers) {
            this.fetchContainerOffers();
        }
    },
    watch: {
        containerOffers(value) {
            if (value.length === Object.values(this.containers).length) {
                this.loadedContainerOffers = true;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            containers: 'units/containers'
        }),
        canLoadedContainerOffers() {
            return this.containerOffers.length !== Object.values(this.containers).length
        },
        hasSeaTransportType() {
            return this.transportTypes.indexOf(transportTypes.SEA) > -1
        },
        otherRoutes() {
            return this.routes.filter((route) => route.base_type !== transportBasicTypes.FCL)
        },
        fclRoutes() {
            return this.routes.filter((route) => route.base_type === transportBasicTypes.FCL)
        }
    },
    methods: {
        isSelected(offer) {
            if (!this.selectedOffer) return false;
            return this.selectedOffer.offer_number === offer.offer_number;
        },

        hasEnoughBalance(offer) {
            const offerPrice = offer.total_price.base.amount ?? offer.total_price.base;
            return parseInt(offerPrice) <= parseInt(this.user.balance.base.amount);
        },

        handleSelectOffer(offer) {
            if (!this.hasEnoughBalance(offer)) {
                this.showBalanceNoticeModal = true;
            } else {
                if (JSON.stringify(offer) === JSON.stringify(this.selectedOffer)) {
                    this.selectedOffer = null;
                    this.$emit('selectedOffer', {offer: null})
                } else {
                    this.selectedOffer = offer;
                    this.$emit('selectedOffer', {offer})
                }
            }
        },
        async fetchContainerOffers() {
            const containers = Object.values(this.containers)
                .filter((container) => this.containerOffers.indexOf(container) === -1)

            if (!containers.length) {
                this.loadedContainerOffers = true;
            } else {
                containers.forEach(async (container) => {
                    try {
                        await this.$store.dispatch('checkout/fetchContainerOffer', container);
                    } catch (e) {
                        console.error(e);
                    }
                    this.containerOffers.push(container);
                })
            }
        }
    }
}
</script>

<style lang="scss">
.route {

    &-wrapper {
        .disabled {
            order: 1;
        }
    }

    &-offer {
        position: relative;
        border: $card-border-width solid $card-border-color;
        @include border-radius($card-border-radius);
        padding: $card-spacer-x;
        color: $card-color;
        height: 100%;
        @include transition($btn-transition);
        cursor: pointer;
        overflow: hidden;
        padding-left: calc(66px);

        &.active {
            background-color: $gray-100;
        }

        &.disabled {
            opacity: 0.4;
        }

        &.placeholder {
            filter: grayscale(1) blur(5px);
            pointer-events: none;
        }

        &-wrap {
            position: relative;
            overflow: hidden;
            @include border-radius($card-border-radius);

            &.placeholder {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border: $card-border-width solid $card-border-color;
                    z-index: 1;
                    @include border-radius($card-border-radius);
                    cursor: not-allowed;
                }
            }

            &-preloader {
                display: block;
                position: relative;
                left: 50%;
                top: 50%;
                width: 80px;
                height: 80px;
                margin: -40px 0 0 -40px;

                &,
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    border: 2px solid transparent;
                    border-radius: 50%;
                }

                & {
                    border-top-color: $primary;
                    animation: spinner-border 1s linear infinite;
                }

                &:before {
                    top: 5px;
                    left: 5px;
                    right: 5px;
                    bottom: 5px;
                    border-top-color: lighten($primary, 10);
                    animation: spinner-border 6s linear infinite;
                }

                &:after {
                    top: 15px;
                    left: 15px;
                    right: 15px;
                    bottom: 15px;
                    border-top-color: lighten($primary, 20);
                    animation: spinner-border 3s linear infinite;
                }
            }
        }

        &-type {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 46px;
            background-color: $primary;
            background-position: center 30px;
            background-repeat: no-repeat;
        }

        &-title {
            margin: 0 0 0 40px;
            width: 100%;
            @include media-breakpoint-down('x2') {
                font-size: $h5-font-size;
            }
            @include media-breakpoint-down('xl') {
                margin-left: 20px;
            }
            @include media-breakpoint-down('xs') {
                margin-left: 0;
                margin-top: 1.5rem;
            }
        }

        &-image {
            display: flex;

            @include media-breakpoint-between('sm', 'xl3') {
                max-width: 122px;
                flex: 0 0 122px;
            }

            @include media-breakpoint-up('xl3') {
                max-width: 136px;
                flex: 0 0 136px;
            }

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        &-package {
            @include media-breakpoint-up-px(1700) {
                display: none;
            }

            @include media-breakpoint-up-px(1200) {
                @include media-breakpoint-down-px(1499) {
                    display: none;
                }
            }
        }

        &-details {

            @include media-breakpoint-up-px(1700){
                flex-wrap: nowrap;
            }

            &-item {
                color: $secondary;

                strong {
                    display: block;
                    line-height: 1em;
                    color: $gray-700;
                    font-size: $h4-font-size;
                    font-weight: $font-weight-bold !important;
                    margin-top: 0.25rem;

                    @include media-breakpoint-down('sm') {
                        font-size: $h4-font-size * 0.9
                    }
                }

                &-package {
                    @include media-breakpoint-up-px(1500) {
                        @include media-breakpoint-down-px(1699) {
                            display: none !important;
                        }
                    }
                    @include media-breakpoint-down-px(1199) {
                        display: none !important;
                    }

                }

                &-service {
                }

                &-price {
                    text-align: right;

                    @include media-breakpoint-down-px(479) {
                        text-align: left;
                        order: -1;
                        width: 100%;
                        flex: 0 0 100%;
                        margin-bottom: 1rem;
                    }

                    div {
                        display: inline-block;
                        text-align: left;
                    }
                }

                &-time {
                    @include media-breakpoint-up-px(1500) {
                        @include media-breakpoint-down-px(1699) {
                            order: 2;
                            flex: 100% !important;
                            max-width: 100% !important;
                            margin-top: 1.5rem;
                        }
                    }
                    @include media-breakpoint-down-px(1199) {
                        order: 2;
                        flex: 100% !important;
                        max-width: 100% !important;
                        margin-top: 1rem;
                    }
                }
            }
        }

        &-option {
            &-item {
                &:last-child {
                    margin-bottom: 0 !important;
                }

                svg {
                    width: 22px;
                    flex: 0 0 22px;
                }
            }
        }
    }
}
</style>
