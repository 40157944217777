<template>
    <div class="card mt-5 mb-4">
        <div class="card-body py-lg-4 px-lg-5">
            <h5 class="text-primary mb-4 font-weight-medium">3. Items</h5>

            <div class="position-relative">
                <Table class="table-items table-responsive" :data="items" :cols="colsCast" :sortable="false"/>
            </div>

            <p v-if="castGrossWeight || castShipmentVolume" class="mb-0 mt-3 mt-md-5 ">
                <span v-if="castGrossWeight" class="font-weight-medium d-block d-lg-inline-block">
                    Gross weight:
                    <strong class="font-weight-semibold text-primary">{{ castGrossWeight }}&nbsp;{{
                            unit.weight || null
                        }}</strong>
                </span>
                <span v-if="castShipmentVolume" class="font-weight-medium ml-lg-7">
                    Shipment volume:
                    <strong class="font-weight-semibold text-primary">{{ castShipmentVolume }}<sup>3</sup></strong>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Table from "@/components/table/Table";

export default {
    name: "SummaryItems",
    components: {Table},
    data: () => ({
        cols: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Name'
            },
            weight: {
                label: 'Weight'
            },
            length: {
                label: 'Length'
            },
            width: {
                label: 'Width'
            },
            height: {
                label: 'Height'
            },
            quantity: {
                label: 'Quantity'
            }
        }
    }),
    props: {
        items: {
            type: Array
        },
        items_unit: String,
    },
    computed: {
        ...mapGetters({
            'units': 'units/units',
        }),
        unit() {
            const {short_label} = this.units[this.items_unit];
            const [measure, weight] = short_label.split('/');

            return {measure, weight};
        },
        colsCast() {
            const cols = {};
            for (const [col, colData] of Object.entries(this.cols)) {
                if (!['id', 'quantity', 'name'].includes(col)) {
                    if (col === 'weight') colData['suffix'] = this.unit.weight;
                    else colData['suffix'] = this.unit.measure;
                }
                cols[col] = colData
            }
            return cols;
        },

        castGrossWeight() {
            const weight = this.items.reduce((previousValue, {weight, quantity}) => {
                if (!weight || !quantity) return previousValue;
                return previousValue + (weight ? parseFloat(weight) * quantity : 0);
            }, 0);
            return `${weight.toFixed(2)}`;
        },

        castShipmentVolume() {
            const volume = this.items.reduce((previousValue, {width, length, height, quantity}) => {
                if (!width || !length || !height || !quantity) return previousValue;
                const volume = parseFloat(width) * parseFloat(length) * parseFloat(height) * parseFloat(quantity)
                return previousValue + volume;
            }, 0);

            if (this.items_unit === 'cm') {
                return `${(volume / 1000000).toFixed(2)} m`;
            } else {
                return `${(volume).toFixed(2)} in`;
            }
        }
    }
}
</script>

<style lang="scss">
$icon-table-items: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrows-angle-expand' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z'/></svg>");

.table-items {
    @include media-breakpoint-down('xs') {
        padding-bottom: 30px;

        &:after {
            content: "";
            height: 20px;
            background-image: escape-svg($icon-table-items);
            background-size: contain;
            width: 20px;
            left: 50%;
            display: block;
            position: absolute;
            transform: translateX(-50%) rotate(45deg);
            bottom: 20px;
        }
    }

    .table {
        &-header {
            &-column {
                background: transparent !important;
                color: $black !important;
                font-weight: $font-weight-medium;
            }
        }

        &-body {
            &-column {
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;

                &-id {
                    width: 50px;
                    text-align: center;
                }

                &-quantity {
                    text-align: center;
                }

                label {
                    display: none;
                }
            }
        }
    }
}
</style>