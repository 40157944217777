<template>
    <div class="row flex-column-reverse flex-xl3-row mx-0 w-100">
        <OfferItem
            :offer="offer"
            :placeholder="true"
        />
        <div
            class="col-xl3-6 mb-3 mb-md-5"
        >
            <div class="route-offer-wrap h-100">
                <a class="route-offer bg-primary border-primary text-white d-block text-decoration-none d-flex justify-content-center flex-column"
                   href="mailto:spotquotes@gepax.us"
                   style="padding-left: 1.25rem;"
                >
                    <span class="d-block h5 mt-4">GEPAX cannot generate a solution for this shipment yet.</span>
                    <span class="d-block h4 mt-3 font-weight-bold mb-4">Get individual rate now!</span>
                </a>
            </div>
        </div>
    </div>

</template>

<script>
import {transportTypes, offerTypes, doorServices} from "../../config/checkout";
import OfferItem from "./OfferItem";

export default {
    name: "EmptyOffers",
    components: {OfferItem},
    data: () => ({
        transportTypes,
        offerTypes,
        doorServices,
    }),
    computed: {
        offer() {
            return {
                offer: 'GEPAX delivers!',
                type: this.randTransportTypes(),
                door_service: this.randDoorService(),
                clearance_costs_included: this.rand(0, 1),
                disclaimer: [],
                transit_time: {
                    unit: '',
                    value: {
                        total: this.rand(3, 10)
                    }
                },
                total_price: {
                    converted: {
                        formatted: this.rand(100, 1000).toFixed(2)
                    }
                },
                package: {}
            }
        }
    },
    methods: {
        randTransportTypes() {
            const transportTypes = Object.values(this.transportTypes);
            const randomTypeKey = this.rand(0, transportTypes.length - 1);
            return transportTypes[randomTypeKey];
        },
        randDoorService() {
            const doorServices = Object.keys(this.doorServices);
            const randomServiceKey = this.rand(0, doorServices.length - 1);
            return doorServices[randomServiceKey]
        },
        rand(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }

}
</script>

<style scoped>

</style>