<template>
    <div class="form-group">
        <label v-if="label" :for="id || name">{{ label }}</label>
        <textarea :type="type"
                  class="form-control"
                  :class="{'is-invalid' : !isValid, [className]: className}"
                  :id="id || name"
                  :name="name"
                  :cols="cols"
                  :rows="rows"
                  :wrap="wrap"
                  :max-length="maxLength"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :readonly="readonly"
                  :required="required"
                  :autofocus="autofocus"
                  v-model="includedValue"
                  v-on="$listeners"
        ></textarea>
        <div v-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError" class="invalid-feedback text-nowrap">The field is required.</div>
        <div v-else-if="!isValid && !v.alphaNum && typeof v.alphaNum !== 'undefined' && showError" class="invalid-feedback text-nowrap">Only alphanumeric characters are allowed.</div>
        <div v-else-if="!isValid && !v.alpha && typeof v.alpha !== 'undefined' && showError" class="invalid-feedback text-nowrap">Only alphabetic characters are allowed.</div>
        <div v-else-if="!isValid && !v.sameAs && typeof v.sameAs !== 'undefined' && showError" class="invalid-feedback text-nowrap">This field must be the same like '{{v.$params.sameAs.eq}}' field.</div>
        <div v-else-if="!isValid && !v.notSameAs && typeof v.notSameAs !== 'undefined' && showError" class="invalid-feedback text-nowrap">This field must be different from the '{{v.$params.notSameAs.notEqualTo}}' field.</div>
        <div v-else-if="!isValid && !v.maxLength && typeof v.maxLength !== 'undefined' && showError" class="invalid-feedback text-nowrap">This field must have at most {{v.$params.maxLength.max}} letters.</div>
        <div v-else-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>

    </div>
</template>

<script>
export default {
    name: "Textarea",
    data: () => ({
        includedValue: null,
    }),
    mounted() {
        this.includedValue = this.value;
    },
    props: {
        type: {
            type: String,
            default: 'text',
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        label: String,
        id: String,
        className: String,
        placeholder: String,
        cols: Number,
        rows: Number,
        maxLength: Number,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        autofocus: Boolean,
        wrap: {
            type: String,
            validator: (value) => ['hard', 'soft'].indexOf(value) !== -1,
        },
        showError: {
            type: Boolean,
            default: true,
        },
        v: [Object,String,undefined, null],
    },
    computed: {
        isValid() {
            if([null,undefined,0,false].indexOf(this.v ) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    },
    watch: {
        value(value){
            this.includedValue = value;
        },
        includedValue(value){
            const {name} = this;
            // if(typeof this.v === 'string') this.v = null;
            this.$emit('change',{name,value});
        },
    }
}
</script>

<style lang="scss">
textarea {
    min-height: 100px;
}
</style>